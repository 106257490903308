.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #4e54c8;
  overflow: hidden;
  pointer-events: none;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 19s linear infinite;
  pointer-events: none;
}




.background li:nth-child(0) {
  left: 59%;
  width: 163px;
  height: 163px;
  bottom: -163px;
  animation-delay: 1s;
  pointer-events: none;
}
.background li:nth-child(1) {
  left: 16%;
  width: 123px;
  height: 123px;
  bottom: -123px;
  animation-delay: 2s;
  pointer-events: none;
}
.background li:nth-child(2) {
  left: 32%;
  width: 142px;
  height: 142px;
  bottom: -142px;
  animation-delay: 1s;
  pointer-events: none;
}
.background li:nth-child(3) {
  left: 69%;
  width: 175px;
  height: 175px;
  bottom: -175px;
  animation-delay: 10s;
  pointer-events: none;
}
.background li:nth-child(4) {
  left: 85%;
  width: 104px;
  height: 104px;
  bottom: -104px;
  animation-delay: 19s;
  pointer-events: none;
}
.background li:nth-child(5) {
  left: 48%;
  width: 143px;
  height: 143px;
  bottom: -143px;
  animation-delay: 21s;
  pointer-events: none;
}
.background li:nth-child(6) {
  left: 3%;
  width: 108px;
  height: 108px;
  bottom: -108px;
  animation-delay: 29s;
  pointer-events: none;
}
.background li:nth-child(7) {
  left: 30%;
  width: 105px;
  height: 105px;
  bottom: -105px;
  animation-delay: 21s;
  pointer-events: none;
}
.background li:nth-child(8) {
  left: 76%;
  width: 180px;
  height: 180px;
  bottom: -180px;
  animation-delay: 36s;
  pointer-events: none;
}
.background li:nth-child(9) {
  left: 27%;
  width: 186px;
  height: 186px;
  bottom: -186px;
  animation-delay: 23s;
  pointer-events: none;
}
.background li:nth-child(10) {
  left: 44%;
  width: 176px;
  height: 176px;
  bottom: -176px;
  animation-delay: 50s;
  pointer-events: none;
}
.background li:nth-child(11) {
  left: 53%;
  width: 185px;
  height: 185px;
  bottom: -185px;
  animation-delay: 53s;
  pointer-events: none;
}
.background li:nth-child(12) {
  left: 1%;
  width: 128px;
  height: 128px;
  bottom: -128px;
  animation-delay: 33s;
  pointer-events: none;
}
.background li:nth-child(13) {
  left: 80%;
  width: 104px;
  height: 104px;
  bottom: -104px;
  animation-delay: 64s;
  pointer-events: none;
}
.background li:nth-child(14) {
  left: 76%;
  width: 168px;
  height: 168px;
  bottom: -168px;
  animation-delay: 23s;
  pointer-events: none;
}
.background li:nth-child(15) {
  left: 7%;
  width: 177px;
  height: 177px;
  bottom: -177px;
  animation-delay: 18s;
  pointer-events: none;
}
.background li:nth-child(16) {
  left: 8%;
  width: 147px;
  height: 147px;
  bottom: -147px;
  animation-delay: 3s;
  pointer-events: none;
}
.background li:nth-child(17) {
  left: 85%;
  width: 165px;
  height: 165px;
  bottom: -165px;
  animation-delay: 26s;
  pointer-events: none;
}
.background li:nth-child(18) {
  left: 1%;
  width: 166px;
  height: 166px;
  bottom: -166px;
  animation-delay: 29s;
  pointer-events: none;
}
.background li:nth-child(19) {
  left: 37%;
  width: 176px;
  height: 176px;
  bottom: -176px;
  animation-delay: 30s;
  pointer-events: none;
}
.background li:nth-child(20) {
  left: 78%;
  width: 110px;
  height: 110px;
  bottom: -110px;
  animation-delay: 60s;
  pointer-events: none;
}
.background li:nth-child(21) {
  left: 71%;
  width: 195px;
  height: 195px;
  bottom: -195px;
  animation-delay: 30s;
  pointer-events: none;
}
.background li:nth-child(22) {
  left: 62%;
  width: 104px;
  height: 104px;
  bottom: -104px;
  animation-delay: 45s;
  pointer-events: none;
}
.background li:nth-child(23) {
  left: 67%;
  width: 196px;
  height: 196px;
  bottom: -196px;
  animation-delay: 37s;
  pointer-events: none;
}
.background li:nth-child(24) {
  left: 62%;
  width: 151px;
  height: 151px;
  bottom: -151px;
  animation-delay: 73s;
  pointer-events: none;
}
.background li:nth-child(25) {
  left: 65%;
  width: 188px;
  height: 188px;
  bottom: -188px;
  animation-delay: 60s;
  pointer-events: none;
}
.background li:nth-child(26) {
  left: 77%;
  width: 102px;
  height: 102px;
  bottom: -102px;
  animation-delay: 114s;
  pointer-events: none;
}
.background li:nth-child(27) {
  left: 25%;
  width: 189px;
  height: 189px;
  bottom: -189px;
  animation-delay: 38s;
  pointer-events: none;
}
.background li:nth-child(28) {
  left: 30%;
  width: 167px;
  height: 167px;
  bottom: -167px;
  animation-delay: 107s;
  pointer-events: none;
}
.background li:nth-child(29) {
  left: 74%;
  width: 157px;
  height: 157px;
  bottom: -157px;
  animation-delay: 84s;
  pointer-events: none;
}
.background li:nth-child(30) {
  left: 22%;
  width: 197px;
  height: 197px;
  bottom: -197px;
  animation-delay: 76s;
  pointer-events: none;
}
.background li:nth-child(31) {
  left: 63%;
  width: 100px;
  height: 100px;
  bottom: -100px;
  animation-delay: 114s;
  pointer-events: none;
}
.background li:nth-child(32) {
  left: 7%;
  width: 191px;
  height: 191px;
  bottom: -191px;
  animation-delay: 98s;
  pointer-events: none;
}
.background li:nth-child(33) {
  left: 76%;
  width: 137px;
  height: 137px;
  bottom: -137px;
  animation-delay: 134s;
  pointer-events: none;
}
.background li:nth-child(34) {
  left: 47%;
  width: 136px;
  height: 136px;
  bottom: -136px;
  animation-delay: 74s;
  pointer-events: none;
}
.background li:nth-child(35) {
  left: 7%;
  width: 101px;
  height: 101px;
  bottom: -101px;
  animation-delay: 130s;
  pointer-events: none;
}
.background li:nth-child(36) {
  left: 6%;
  width: 163px;
  height: 163px;
  bottom: -163px;
  animation-delay: 82s;
  pointer-events: none;
}
.background li:nth-child(37) {
  left: 28%;
  width: 177px;
  height: 177px;
  bottom: -177px;
  animation-delay: 4s;
  pointer-events: none;
}
.background li:nth-child(38) {
  left: 49%;
  width: 132px;
  height: 132px;
  bottom: -132px;
  animation-delay: 110s;
  pointer-events: none;
}
.background li:nth-child(39) {
  left: 5%;
  width: 124px;
  height: 124px;
  bottom: -124px;
  animation-delay: 49s;
  pointer-events: none;
}
.background li:nth-child(40) {
  left: 35%;
  width: 123px;
  height: 123px;
  bottom: -123px;
  animation-delay: 175s;
  pointer-events: none;
}

html {
  height: 100%;
}
body {
  margin:0;
  padding:0;
  font-family: sans-serif;
  background: linear-gradient(#141e30, #243b55);
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box form button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.login-box button:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box button span {
  position: absolute;
  display: block;
}

.login-box button {
  background-color: transparent;
  border: none;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

.login-box button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}


@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.login-box button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.login-box button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}


.login-box button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}